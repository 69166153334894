<template>
  <div>
    <div class="uk-text-large">Contacto</div>
    <div class="uk-margin-small-top" v-if="!isLoading">
      <div
        class="
          uk-card
          uk-border-rounded
          uk-card-default
          uk-padding-small
          uk-width-1-3
        "
      >
        <div class="uk-text-large uk-text-bold">
          {{ contact.first_name }}
          {{ contact.last_name }}
        </div>
        <div>
          <span class="uk-text-bold">Teléfono:</span>
          {{ contact.phone }}
        </div>
        <div>
          <span class="uk-text-bold">Correo:</span>
          {{ contact.email }}
        </div>
      </div>
    </div>
    <div class="uk-text-center" v-else>
      <div uk-spinner></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactIndex",

  data() {
    return {
      contact: null,
      isLoading: true,
    };
  },

  mounted() {
    this.isLoading = true;
    this.axios
      .get("/contact")
      .then(({ data: { contact } }) => {
        this.contact = contact;
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  // props: ["payload"],
};
</script>
